<!--

https://stackoverflow.com/questions/48035310/open-a-vuetify-dialog-from-a-component-template-in-vuejs

-->


<template>
  <v.dialog v-model="show" :loading="loading">

    <template #title-left>Новый проект</template>

    <template #actions>
      <v-btn :disabled="btnOK_disabled" text @click="closeOK">
        OK
      </v-btn>
    </template>

    <v-row>
      <v-col cols="12" md="8">
        <v-text-field
            label="Название"
            v-model="project.name"
            :error-messages="project_message.name"
            :counter="30"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
            label="Полное название или краткое описание"
            v-model="project.longname"
            :error-messages="project_message.longname"
            :counter="200"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-checkbox
            v-model="project.private"
            label="Ограничить доступ"
        ></v-checkbox>
      </v-col>
    </v-row>

  </v.dialog>
</template>

<script>
let normal_data = {}

export default {
  props: {value: Boolean, data: Object},
  data: function () {
    return {
      loading: false,
      btnOK_disabled: false,
      project: {name: null, longname: null, private:false},
      project_message: {name: null, longname: null},
    }
  },
  computed: {
    show: {
      get() {
        if (this.value) this.form_show()
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        // this.$emit('close', value)
      }
    }
  },
  methods: {
    closeOK() {
      this.$axios.post(this.$url.core_mix_projects, this.project, {zLoader: [this, 'loading']})
          .then(response => {
            this.$store.state.user.projects.push(response.data)
            this.$emit('closeOK', response.data, 'core-project_owner')
            this.show = false
          })
          .catch(error => {
            if (error.response.data.name)
              this.project_message.name = error.response.data.name
          })
    },
    form_show() {
      this.$lib.restore(this.$data, normal_data)
    },
  },
  components: {
    "v.dialog": () => import('@/site/componets/dialog'),
  },
  created() {
    normal_data = this.$lib.copy(this.$data)
  }
}
</script>
